// status: Array<string>, //offen, vorbereitend, laufend, nachbereitend, abgesagt, abgeschlossen
"use strict";
export interface IF_rowEvent {
  ID?: number;
  project?: number;
  name?: string;
  status?: number;
  prestart?: Date;
  start?: Date;
  end?: Date;
  postend?: Date;
  location?: string;
  time?: string;
  responsible?: number;
  section?: number;
  isSet?: boolean;
  isMilestone?: boolean;
  isWorkEvent?: boolean;
  isSerial?: boolean;
  startSER?: Date;
  endSER?: Date;
  turnusSER?: number;
  members?: Array<number>;
  actions?: Array<number>;
}

export class Event {
  ID?: number
  project: number
  name: string
  status: number
  prestart: Date
  start: Date
  end: Date
  postend: Date
  location: string
  time: string
  responsible: number
  section: number
  isSet: boolean
  isMilestone: boolean
  isWorkEvent: boolean
  isSerial: boolean
  startSER?: Date
  endSER?: Date
  turnusSER?: number
  members: Array<number>
  actions: Array<number>

  constructor () {
    const now = new Date()
    this.ID = undefined
    this.project = 0
    this.name = ''
    this.status = 0
    this.prestart = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
    this.start = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    this.end = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    this.postend = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
    this.location = ''
    this.time = ''
    this.responsible = 0
    this.section = 0
    this.isSet = false
    this.isMilestone = false
    this.isWorkEvent = false
    this.isSerial = false
    this.startSER = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    this.endSER =new Date(now.getFullYear(), now.getMonth(), now.getDate())
    this.turnusSER = 0
    this.members = []
    this.actions = []
  }
}

export class Projekt {
  ID?: number
  name: string
  color: string
  constructor (name :string) {
    this.ID = undefined
    this.name = name
    this.color = '#000000'
  }
}

export class Fachgruppe {
  ID?: number
  
  constructor (
    public name :string
  ) {}
}

export class Aufgabe {
  ID?: number
  
  constructor (
    public name :string    
  ) {}
}

export interface IF_freeDayListItem {
  ID?: number;
  start?: Date;
  stop?: Date;
  days?: number;
  workdays?: number;
  workerID?: number;
}

export class freeDayListItem {
  ID? = 0
  start = new Date()
  stop = new Date()
  days = 0
  workdays = 0
  workerID?: number

  constructor(start: Date, stop: Date, workerID?: number) {
    this.start = start
    this.stop = stop
    this.workerID = workerID
  }
}

/**
 * Interface to Teammitarbeiter
 * @param id: number
 * @param name string
 * @param email string
 * @param phone string
 * @param freedays array of freedaylist
 */
export interface IF_teammitarbeiter {
  ID?: number;
  name?: string;
  short?: string;
  email?: string;
  phone?: string;
  freedays?: Array<number>;
}

export class Teammitarbeiter {
  ID?: number
  name: string
  short: string
  email: string
  phone: string
  freedays?: Array<number>
  
  constructor () {
    this.name = "";
    this.short = "";
    this.email = "";
    this.phone = "";
  }
  
}

export default { Projekt, Teammitarbeiter, Event }