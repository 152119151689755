


























import Vue from 'vue'
import { getModule } from 'vuex-module-decorators';
import Projekte from '@/store/projekte';
import Fachgruppen from '@/store/fachgruppen';
import Mitarbeiter from '@/store/mitarbeiter';
import Termin from '@/store/termin'
import Aufgaben from '@/store/aufgaben';
import store from '@/store';

const projectModule = getModule(Projekte, store)
const groupModule = getModule(Fachgruppen, store)
const coworkerModule = getModule(Mitarbeiter, store)
const eventModule = getModule(Termin, store)
const taskModule = getModule(Aufgaben, store)

export default Vue.extend({
  created() {
    projectModule.fetchData();
    groupModule.fetchData();
    coworkerModule.fetchData();
    eventModule.fetchData();
    taskModule.fetchData();
  },
  watch: {
    '$store.state.errormessage': function() {
      if(this.$store.state.errormessage !== "")
        this.$bvToast.toast(`${this.$store.state.errormessage}`, {
          title: 'FEHLER',
          autoHideDelay: 3000,
          appendToast: true,
          variant: 'danger',
          solid: true
        })
      this.$store.state.errormessage = ""      
    },
    '$store.state.infomessage': function() {
      if(this.$store.state.errormessage !== "")
        this.$bvToast.toast(`${this.$store.state.infomessage}`, {
          title: 'Information',
          autoHideDelay: 3000,
          appendToast: true,
          variant: 'info',
          solid: true
        })
      this.$store.state.infomessage = ""      
    },

  }
  
})
