import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { freeDayListItem, IF_freeDayListItem } from '@/models'
import axios from 'axios'

function calDays(start: Date, stop: Date) {
  return Math.ceil(Math.abs(stop.getTime() - start.getTime()) / (1000 * 3600 * 24)) + 1
}

function calWorkDays(start: Date, stop: Date) {
  // TODO
  return 0
}

@Module({
  name: 'Freitag',
  stateFactory: true,
  namespaced: true
})

export default class Mitarbeiter extends VuexModule {
  freeDays: Array<freeDayListItem> = []

  @Mutation
  POST_axios (pl: {path: string, obj: IF_freeDayListItem}) {    
    axios.post(
      pl.path,
      pl.obj, 
      { headers: {'Content-type': 'application/x-www-form-urlencoded'} }
    )
    .then(response => {
      console.log(response.data.message)
    })
    .catch( function (error) {
      console.error(error)
    });
  }

  @Mutation
  SET_freeDays(freeDayList: Array<freeDayListItem>) {
    freeDayList.map(itm => {
      itm.days = calDays(new Date(itm.start), new Date(itm.stop));
      itm.workdays = calWorkDays(new Date(itm.start), new Date(itm.stop));
    });
    this.freeDays = freeDayList;
  }

  @Mutation
  empty_freeDays() {
    this.freeDays = [];
  }

  @Action
  async fetchData (ID: number) {
    await axios.get(this.context.rootState.host + `/coworkers/freedays/get.php?ID=${ID}`)
    .then(response => {
      if(response.data.records.length > 0)
        this.SET_freeDays(response.data.records);
      else{
        console.log('fetch:' + response.data.message);
        this.empty_freeDays();
      }
    })
    .catch( (error) => {
      if (error.response) {        
        if (error.response.status === 404)
          this.context.rootState.infomessage = error.response.data.message
        console.error(error.response.data.message)
      } else if (error.request) {
        console.error(error.request);
        this.context.rootState.errormessage = 'Verbindungsfehler zur API!'
      } else {
        console.error('Error', error.message);
        this.context.rootState.errormessage = 'Keine Verbindung zur Datenbank!'
      }
      this.empty_freeDays();
    });
  }

  //{start: date, stop: date, days: number, workdays: number}
  @Action
  addfreeDay (m : freeDayListItem) {
    this.POST_axios({path: this.context.rootState.host + '/coworkers/freedays/add.php', obj: m });
  }

  @Action
  delfreeDay (ID: number) {
    this.POST_axios({path: this.context.rootState.host + '/coworkers/freedays/del.php', obj: { ID } });
  }

/*   @Action
  changefreeDay (m: freeDayListItem) {
    this.POST_axios({path: this.context.rootState.host + '/coworkers/freedays/change.php', obj: m });
  } */
}