import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Aufgabe } from '@/models'
import axios from 'axios'

@Module({
  name: 'Aufgaben',
  stateFactory: true,
  namespaced: true
})
export default class Aufgaben extends VuexModule {
  tasks: Array<Aufgabe> = []

  @Mutation
  POST_axios (pl: {path: string, obj: {ID?: number; name?: string}}) {    
    axios.post(
      pl.path,
      pl.obj, 
      { headers: {'Content-type': 'application/x-www-form-urlencoded'} }
    )
    .then(response => {
      console.log(response.data.message)
    })
    .catch( function (error) {
      console.error(error)
    });
  }

  @Mutation
  SET_tasks(Aufgaben: Array<Aufgabe>) {
    this.tasks = Aufgaben;
  }

  get getTaksOptions () {    
    return this.tasks.map((val,i) => ({value: i, text: val.name}))
  }

  @Action
  async fetchData () {
    const response = await axios.get(this.context.rootState.host + '/task/get.php');
    if(response.data.records.length > 0)
      this.SET_tasks(response.data.records);
    else
      console.log(response.data.message)
  }

  @Action
  addAufgabe (aufgabenname: string) {
    this.POST_axios({path: this.context.rootState.host + '/task/add.php', obj: { name: aufgabenname } });
  }

  @Action
  delAufgabe (ID: number) {
    this.POST_axios({path: this.context.rootState.host + '/task/del.php', obj: { ID } });
  }

  @Action
  changeAufgabe (p: {ID: number; name: string}) {
    this.POST_axios({path: this.context.rootState.host + '/task/change.php', obj: p });
  }
}