import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Event, IF_rowEvent } from '@/models'
import axios from 'axios'

@Module({
  name: 'Termin',
  stateFactory: true,
  namespaced: true
})
export default class Termin extends VuexModule {
  events: Array<Event> = []

  @Mutation
  POST_axios (pl: {path: string, obj: IF_rowEvent}) {    
    axios.post(
      pl.path,
      pl.obj, 
      { headers: {'Content-type': 'application/x-www-form-urlencoded'} }
    )
    .then(response => {
      console.log(response.data.message);
      // console.log(this.context.rootState.successmessage)
      // this.context.rootState.successmessage = response.data.message;
    })
    .catch( (error) => {
      console.error(error)
    });
  }

  @Mutation
  SET_coworkers(Events: Array<Event>) {
    this.events = Events;
  }

  @Action
  async fetchData () : Promise<boolean> {
    await axios.get(this.context.rootState.host + '/event/get.php')
    .then(response => {
      if(response.data.records.length > 0) {
        this.SET_coworkers(response.data.records);
        return new Promise (resolve => resolve(false))
      }
      else
        console.log('fetch:' + response.data.message);
    })
    .catch( (error) => {
      if (error.response) {
        if (error.response.status === 404)
          this.context.rootState.infomessage = error.response.data.message
        console.error(error.response.data.message)
      } else if (error.request) {
        console.error(error.request);
        this.context.rootState.errormessage = 'Verbindungsfehler zur API!'
      } else {
        console.error('Error', error.message);
        this.context.rootState.errormessage = 'Keine Verbindung zur Datenbank!'
      }
    });
    return new Promise (resolve => resolve(false))
  }

  @Action
  addTermin (m : Event) {
    console.log(m);
    this.POST_axios({path: this.context.rootState.host + '/event/add.php', obj: m });
  }

  @Action
  delTermin (ID: number) {
    this.POST_axios({path: this.context.rootState.host + '/event/del.php', obj: { ID } });
  }

  @Action
  changeTermin (m: Event) {
    this.POST_axios({path: this.context.rootState.host + '/event/change.php', obj: m });
  }

  @Action
  changeTerminElement (m: {ID: number, column: string, element: string}) {
    if(m.column === "_showDetails") return
    this.POST_axios({path: this.context.rootState.host + '/event/changesingle.php', obj: m });
  }
}