






























import { Component, Prop, Vue } from 'vue-property-decorator';
import TextEdit from "@/components/TextEdit.vue";

@Component({
  components: {    
    TextEdit    
  },
})
export default class CardList extends Vue{
  @Prop() value!: Array<any>;
  @Prop() header!: string;
  @Prop() tmpName!: string;
  private visible = false
  private name = '';

  public add(): void {
    if (this.name.length === 0) {
      this.$swal('Warnung', 'Name darf nicht leer sein.', 'warning' )
      return
    }
    if (this.value.some(pj => pj['name'].toLowerCase() === this.name.toLowerCase())){
      this.$swal('Warnung', 'Name existiert schon.', 'warning' )
      return
    }
    this.$emit('addData', this.name);    
    this.$emit('fetchdata');
    this.name = '';
  }
  
  public del(eleID: number): void {
    this.$emit('delData', eleID);
    this.$emit('fetchdata');
  }

  public change(eleID: number, name: string): void {
    this.$emit('changeData', {ID: eleID, name});
  }
}
