























































import { Component, Prop, Vue } from "vue-property-decorator";

function pad(n: string, width: number, z: string) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function getWeekNumber(d: Date) {
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
}

class Tag {
  tag: string;
  datum: string;
  Wochennummer?: number;
  date?: Date;
  class?: string;

  constructor (tag = '', datum = '') {
    this.tag = tag
    this.datum = datum
  }
}

interface Termin {
  start: Date,
  end: Date,
  name: string,
  project: number,  
  isSet: boolean
}

interface pjColors {
  value: number,
  text: string,
  color: string
}

interface monthRow { 
  [key: string]: Tag 
}

@Component
export default class KalenderJahr extends Vue {
  @Prop() year: number = new Date().getFullYear()
  @Prop() termine!: Array<Termin>;
  @Prop() projektColors!: Array<pjColors>;
  private tage= ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
  private monate = [{ key: 'Januar', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'Februar', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'März', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'April', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'Mai', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'Juni', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'Juli', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'August', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'September', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'Oktober', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'November', tdClass: "cellClass", tdAttr: "cellAttr" },
        { key: 'Dezember', tdClass: "cellClass", tdAttr: "cellAttr" }]
  // private calenderdays: Array<any> = []

  mounted() {
    // this.createYearList
  }

  projekttermine (value: any) {
    let projekte: any = []
    if('date' in value) {
        var temp = this.termine.filter(x => x.start <= value.date && x.end >= value.date)
        if(temp != undefined)
          projekte.push(...temp)
    }
    return projekte
  }

  projektcolor(id: number) {  
    return this.projektColors.find(x => x.value === id)?.color
  }

  cellClass(value: any) {
    if(value.class === 'secondary'){        
      return `bg-${value.class} text-light`
    }
    /* if('date' in value) {
      var temp = this.termine.find(x => x.start <= value.date && x.end >= value.date)        
      if(temp != undefined)
        return 'bg-info'
    } */
  }


  cellAttr(value:any) {
    /* if('date' in value) {
      var temp = this.testTermine.find(x => x.date === value.date.toDateString())
      if(temp != undefined)
        return { 'v-b-tooltip':"hover", title: temp.info }
    } */
  }

  get calenderdays () : Array<any> {
    let calenderdays = [];
    
    for (var cal = 1; cal <= 31; cal++){
      let tempRow: monthRow = {}
      for (var i = 0; i <= 11; i++) {
        let datum = new Date(this.year, i, cal);
        let tempdatum = new Date(this.year, i, 1);
        let tag = this.tage[datum.getDay()];
        if (datum.getMonth() != tempdatum.getMonth()) { tag = "X"; }
        let Wochennummer = getWeekNumber(datum);

        //let tempCell // { tag, datum: pad(cal, 2, "&nbsp"), class: null, Wochennummer }

        switch (tag) {
          case 'Mo':
            tempRow[this.monate[i].key] = { tag, datum: pad(cal.toString(), 2, ""), Wochennummer, date: datum }
            break;
          case 'Sa':
            tempRow[this.monate[i].key] = { tag, datum: pad(cal.toString(), 2, ""), class: 'secondary' }
            break;
          case 'So':
            tempRow[this.monate[i].key] = { tag, datum: pad(cal.toString(), 2, ""), class: 'secondary' }
            break;
          case 'X':
            tempRow[this.monate[i].key] = new Tag ()
            break;
          default:
            tempRow[this.monate[i].key] = { tag, datum: pad(cal.toString(), 2, ""), date: datum }
        }
      }
      calenderdays.push(tempRow)
    }
    return calenderdays;
  }

  get printcalenderDays () : Array<any> {
    let calDays = [];

    for (var cal = 1; cal <= 31; cal++){
      for (var i = 0; i <= 11; i++) {
        calDays[i] =  this.calenderdays[cal][this.monate[i].key]
      }
    }
    return calDays;
  }
}
