import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Projekt } from '@/models'
import axios from 'axios'

const basecolors = ['#96ceb4', '#ffeead', '#ffcc5c', '#ff6f69', '#588c7e', '#f2e394', '#f2ae72', '#d96459']

@Module({
  name: 'Projekte',
  stateFactory: true,
  namespaced: true
})
export default class Projekte extends VuexModule {
  projects: Array<Projekt> = []
  colors = basecolors

  getcolor(ind: number) {
    return this.colors[ind % this.colors.length]
  }

  @Mutation
  POST_axios (pl: {path: string, obj: {ID?: number ; name?: string}}) {
    axios.post(
      pl.path,
      pl.obj, 
      { headers: {'Content-type': 'application/x-www-form-urlencoded'} }
    )
    .then(response => {
      console.log(response.data.message)
    })
    .catch( function (error) {
      console.error(error)
    });
  }

  @Mutation
  SET_projekte(projekte: Array<Projekt>) {
    this.projects = projekte;
  }

  get getProjectOptions () {    
    return this.projects.map((val,i) => ({value: i, text: val.name}))
  }

  get getProjectColorOptions () {    
    return this.projects.map((val,i) => ({value: i, text: val.name, color: this.colors[i % this.colors.length]}))
  }

  @Action
  async fetchData () {
    const response = await axios.get(this.context.rootState.host + '/project/get.php');
    this.SET_projekte(response.data.records);
  }

  @Action
  addProjekt (projektname: string) {
    this.POST_axios({path: this.context.rootState.host + '/project/add.php', obj: { name: projektname } });
  }

  @Action
  delProjekt (ID: number) {
    this.POST_axios({path: this.context.rootState.host + '/project/del.php', obj: {ID } });
  }

  @Action
  changeProjekt (p: {ID: number; name: string}) {
    this.POST_axios({path: this.context.rootState.host + '/project/change.php', obj: p });
  }
}
