






















































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from 'vuex-module-decorators';
import Projekte from '@/store/projekte';
import Fachgruppe from '@/store/fachgruppen'
import Mitarbeiter from '@/store/mitarbeiter';
import Aufgaben from '@/store/aufgaben';
import store from '@/store';
import CalEdit from '@/components/CalEdit.vue'; // @ is an alias to /src
import TextEdit from '@/components/TextEdit.vue';
import SelectEdit from '@/components/SelectEdit.vue';
import SelectTeamMember from '@/components/SelectTeamMember.vue';
import * as models from "../models";
import Termin from "@/store/termin";
const projectModule = getModule(Projekte, store);
const groupModule = getModule(Fachgruppe, store);
const coworkerModule = getModule(Mitarbeiter, store);
const eventModule = getModule(Termin, store);
const taskModule = getModule(Aufgaben, store);

@Component({
  components: {
    CalEdit,
    TextEdit,
    SelectEdit,
    SelectTeamMember
  },
})
export default class Events extends Vue {
  private isBusy = false;
  private showfilter = false;
  private showChangeTermin = false;
  private deactiveChangeTermin = true;
  private tTermin: models.Event = new models.Event();
  private selectedTermin = "";
  private teamMembers: Array<number> = [];

  private fields = [
    {
      key: "project",
      label: "Projekt",
      sortable: true,
    },
    {
      key: "name",
      label: "Termin",
      sortable: true,
    },    
    {
      key: "status",
      label: "Status",
      sortable: true,
    },
    /* {
      key: "prestart",
      label: "Vorbereitung",
      sortable: true,
    }, */
    {
      key: "start",
      label: "Start",
      sortable: true,
    },
    {
      key: "end",
      label: "Ende",
      sortable: true,
    },
    /* {
      key: "postend",
      label: "Nachbereitung",
      sortable: true,
    }, */
    {
      key: "location",
      label: "Ort / Raum",
      sortable: true,
    },    
    {
      key: "time",
      label: "Zeit",
      sortable: true,
    },
    {
      key: "responsible",
      label: "Verantwortlich",
      sortable: true,
    },
    {
      key: "section",
      label: "Fachgruppe",
      sortable: true,
    },
    { key: "actionbutton", label: "Aktion" },
    {
      key: "members",
      label: "",
      
    },
    { key: "eventbutton", label: "" },    
    {
      key: "isSet",
      label: "",
      sortable: true,
    },
    { key: "editbutton", label: "" }
  ];
  private events: Array<models.IF_rowEvent> = []

  created () {
    this.fetchTerminData();
  }

  private status_options = [
    {value: 0, text: "offen"},
    {value: 1, text: "abgeschlossen"},
    {value: 2, text: "vorbereitend"},
    {value: 3, text: "laufend"},
    {value: 4, text: "nachbereitend"},
    {value: 4, text: "abgesagt"}
  ];
  
  private serien_options = [
    {value: 0, text: "Bitte eine Option auswählen"},    
    {value: 7, text: "Wöchentlich"},
    {value: 30, text: "Monatlich"},
    {value: 90, text: "Quartal"},
    {value: 365, text: "Jährlich"}
  ];

  private project_options = projectModule.getProjectOptions;
  private group_options = groupModule.getGroupsOptions;
  private worker_options = coworkerModule.getWorkerOptions;
  private task_options = taskModule.getTaksOptions;

  private infot(item: models.Event){    
    this.tTermin = item
    this.selectedTermin = JSON.stringify(this.tTermin);
  }

  async fetchTerminData () {
    this.isBusy = true;
    this.isBusy = await eventModule.fetchData();    
    this.events = eventModule.events;
  }

  openTerminModal(){
    this.showChangeTermin = false;
    this.deactiveChangeTermin = true;
    this.tTermin = new models.Event();
  }

  sendEditTermin () {
    this.$bvModal.hide('modalAddTermin');
    eventModule.changeTermin(this.tTermin);
    this.showChangeTermin = false;
    this.deactiveChangeTermin = true;
    this.tTermin = new models.Event();
    this.fetchTerminData();
  }

  @Watch('tTermin', {deep: true})
  onTterminChange(value: models.Event, oldvalue: models.Event) {
    if(oldvalue.name != "" && value.ID === oldvalue.ID)
    {
      this.deactiveChangeTermin = false;
      if(JSON.stringify(value) !== this.selectedTermin && this.showChangeTermin == false){
        let tempTermin = new models.Event()
        Object.assign(tempTermin, JSON.parse(this.selectedTermin))
        
        Object.keys(value).forEach(key => {
          // TODO die any definition muss überarbeitet werden
          if((tempTermin as any)[key] != (value as any)[key]){
            if(value.ID != undefined)
              eventModule.changeTerminElement({ID: value.ID, column: key, element: (value as any)[key] })
          }
        });
      }
    }
  }

  editTM (item: models.Event) {
    this.tTermin = item;
    this.showChangeTermin = true;
    this.$bvModal.show('modalAddTermin');
  }

  changeTerminMembers(numberMembers: Array<number>) {
    this.teamMembers = numberMembers;
  }
  
  changeTerminMembersDB() {
    alert('OK')
    this.tTermin.members = this.teamMembers;
  }

  addTermin (event: Event) {
    event.preventDefault();
    if (this.events.some(pj => pj.name?.toLowerCase() === this.tTermin.name.toLowerCase())){
      this.$swal('Warnung', 'Name existiert schon.', 'warning' )
      return
    }
    // this.$bvModal.hide('modalAddMitarbeiter');
    eventModule.addTermin(this.tTermin);
    this.tTermin = new models.Event();
    this.fetchTerminData();
  }

  async onEventSelected (item: Array<models.Event>) {    
    if(!item.length) return;
    if(item[0].ID === undefined) return; // damit kein undefined an fetchdata übergeben wird
    
    this.tTermin = item[0];
    this.selectedTermin = JSON.stringify(this.tTermin);
  }

  deleteTM (item: models.Event) {
    this.$swal({
      title: 'Eintrag löschen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#17a2b8',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Ja, löschen!'
    }).then(result => {
      if (result.isConfirmed) {
        if(item.ID != undefined) {
          eventModule.delTermin(item.ID);
          this.fetchTerminData();
        }
      }
    })
  }

}
