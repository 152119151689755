














import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class TextEdit extends Vue{
  @Prop() private value!: string;
  private tempElem = this.value;
  private isEditing = false;

  public edit() {
    this.isEditing = true;
    const input = this.$el.getElementsByTagName('input')[0];
    input.style.display = '';
    input.focus();
    input.select();
  }

  public editEnd() {    
    this.isEditing = false;
    if(this.value !== this.tempElem)
      this.$emit('input', this.tempElem);
  }
}
