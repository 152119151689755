

























































import { Component, Vue } from 'vue-property-decorator';
import KalenderJahr from '@/components/KalenderJahr.vue'
import { getModule } from 'vuex-module-decorators';
import Projekte from '@/store/projekte';
import store from '@/store';

const projectModule = getModule(Projekte, store);
@Component({
  components: {
    KalenderJahr,
  },
})
export default class Home extends Vue {
  private kalenderFontSize = 1;
  private time = 0;
  private year = 2021;
  //private project_options = projectModule.getProjectColorOptions; 
  private time_options = [
    {value: 0, text: "Jahr"},
    {value: 1, text: "Halbjahr"},
    {value: 2, text: "Quartal"},
    {value: 3, text: "Monate"},
    {value: 4, text: "Woche"},
    {value: 5, text: "Projektansicht"}
  ];

/*   mounted() {
    console.log( 'color' in this.project_options)
    if(!( 'color' in this.project_options[0])) 
      this.project_options = projectModule.getProjectOptions.map(val => ({value: val.value , text: val.text, color: getRandomColor()}));  
  } */

  get project_options () {    
    return projectModule.getProjectColorOptions
  }

  private testTermine = [
    {start: new Date("2021-04-14 00:00"), end: new Date("2021-04-16 23:59"), name: 'Meilenstein1', project: 0, isSet: true},
    {start: new Date("2021-04-15 00:00"), end: new Date("2021-04-16 23:59"), name: 'Meilenstein3', project: 2, isSet: false},
    {start: new Date("2021-05-12 00:00"), end: new Date("2021-05-14 23:59"), name: 'Meilenstein2', project: 1, isSet: true}
  ]
}
