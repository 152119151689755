







































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class SelectTeamMember extends Vue{
  @Prop() private value!: Array<number>;
  @Prop() private options!: {value: number ; text: string}[];
  private tempElem = this.value;
  private temp = this.options.map(el => el.text);
  private werte: Array<string> = [];

  created()  {
    if(Array.isArray(this.value)){
      let tmp = this.value.map(id => {
        let tmp = this.options.find(opt => id === opt.value)?.text
        if(tmp == undefined) return 'fehler'
        return tmp
      });     
      this.werte =  tmp
    }
    else
      this.werte = []
  }

  get availableMemperOptions () {
    this.tempElem = this.werte.map(el => {
      let tmp = this.options.find(opt => el === opt.text)?.value
      if(tmp == undefined) return -1
      return tmp
    })
    if(this.value !== this.tempElem){    
      this.$emit('inputMember', this.tempElem);
    }
    return this.temp.filter(opt => this.werte.indexOf(opt) === -1)
  }
}
