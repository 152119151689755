





































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import Projekte from '@/store/projekte';
import Fachgruppen from '@/store/fachgruppen';
import Mitarbeiter from '@/store/mitarbeiter';
import Freitag from '@/store/freiTag';
import Aufgaben from '@/store/aufgaben';
import store from '@/store';
import * as models from "../models";
import TextEdit from "@/components/TextEdit.vue";
import CardList from "@/components/CardList.vue";

@Component({
  components: {    
    TextEdit,
    CardList
  },
})
export default class Options extends Vue {
  private projectModule = getModule(Projekte, store)
  private groupModule = getModule(Fachgruppen, store)
  private coworkerModule = getModule(Mitarbeiter, store)
  private freeDayModule = getModule(Freitag, store)
  private taskModule = getModule(Aufgaben, store)
  
  private projekte: Array<models.Projekt> = []; 
  private fachgruppen: Array<models.Fachgruppe> = [];
  private aufgaben: Array<models.Aufgabe> = [];
  private mitarbeiter: Array<models.Teammitarbeiter> = [];
  private mitarbeiterfields = [
    {
      key: "name",
      label: "Name",
      sortable: true,
    },
    {
      key: "short",
      label: "Kurz",
      sortable: true,
    },
    {
      key: "email",
      label: "E-Mail",
      sortable: true,
    },
    {
      key: "phone",
      label: "Nummer",
      sortable: true,
    },
    { key: "editbutton", label: "" }];
  private freitage: Array<models.freeDayListItem> = [];
  private freitagefields = [
    {
      key: "start",
      label: "Start",
      sortable: true,
    },
    {
      key: "stop",
      label: "Stop",
      sortable: true,
    },
    {
      key: "days",
      label: "Tage",
      sortable: true,
    },
    {
      key: "workdays",
      label: "Arbeitstage",
      sortable: true,
    },
    { key: "editbutton", label: "" }
  ];

  private tMitarbeiter: models.Teammitarbeiter = new models.Teammitarbeiter();
  private showChangeWorker = false;
  private deativeChangeWorker = true;
  private tagstart = new Date();
  private tagstop = new Date();
  private wID?: number = undefined;
 
  created () {
    this.fetchProjektData();
    this.fetchFachgruppenData();
    this.fetchMitarbeiterData();
    this.fetchAufgabenData();
  }

  addMitarbeiter (event: Event) {
    event.preventDefault();
    if (this.mitarbeiter.some(pj => pj['name'].toLowerCase() === this.tMitarbeiter.name.toLowerCase())){
      this.$swal('Warnung', 'Name existiert schon.', 'warning' )
      return
    }
    // this.$bvModal.hide('modalAddMitarbeiter');
    this.coworkerModule.addMitarbeiter(this.tMitarbeiter)
    this.tMitarbeiter = new models.Teammitarbeiter();
    this.fetchMitarbeiterData();
  }

  async onWorkerSelected (item: Array<models.Teammitarbeiter>) {
    this.wID = item.length ? item[0].ID : undefined;
    if(!item.length) return;
    if(item[0].ID === undefined) return; // damit kein undefined an fetchdata übergeben wird
    
    await this.freeDayModule.fetchData(item[0].ID);    
    this.freitage = this.freeDayModule.freeDays;
  }

  editMB (item: models.Teammitarbeiter) {
    this.tMitarbeiter = item;
    this.showChangeWorker = true;
    this.$bvModal.show('modalAddMitarbeiter');
  }

  sendEditMB () {
    this.$bvModal.hide('modalAddMitarbeiter');
    this.coworkerModule.changeMitarbeiter(this.tMitarbeiter);
    this.showChangeWorker = false;
    this.deativeChangeWorker = true;
    this.tMitarbeiter = new models.Teammitarbeiter();
    this.fetchMitarbeiterData();
  }

  @Watch('tMitarbeiter', {deep: true})
  onTworkerChange(value: models.Teammitarbeiter, oldvalue: models.Teammitarbeiter) {
    if(oldvalue.name != "" && value.ID === oldvalue.ID)
      this.deativeChangeWorker = false;
  }

  removeMB (item: models.Teammitarbeiter) {
    this.$swal({
      title: 'Eintrag löschen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#17a2b8',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Ja, löschen!'
    }).then( result => {
      if (result.isConfirmed) {
        if(item.ID != undefined)
          this.coworkerModule.delMitarbeiter(item.ID);    
        this.fetchMitarbeiterData();
      }
    })
  }

  addDays () {
    if(this.wID === undefined) {
      this.$swal('Warnung', 'Bitte Mitarbeiter auswählen.', 'warning' )
    }
    if (this.freitage.some(pj => pj['start'] === this.tagstart)){
      this.$swal('Warnung', 'Startzeit existiert schon.', 'warning' )
      return
    }
    let tDay = new models.freeDayListItem(this.tagstart, this.tagstop, this.wID)

    this.freeDayModule.addfreeDay(tDay)
  }

  delDays (item: models.freeDayListItem) {
    this.$swal({
      title: 'Eintrag löschen?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#17a2b8',
      cancelButtonColor: '#dc3545',
      confirmButtonText: 'Ja, löschen!'
    }).then(async result => {
      if (result.isConfirmed) {
        if(item.ID != undefined) {
          this.freeDayModule.delfreeDay(item.ID);
          await this.freeDayModule.fetchData(item.ID); 
          this.freitage = this.freeDayModule.freeDays;
        }
      }
    })
  }

  async fetchProjektData () {
    await this.projectModule.fetchData();    
    this.projekte = this.projectModule.projects;
  }

  async fetchFachgruppenData () {  
    await this.groupModule.fetchData();    
    this.fachgruppen = this.groupModule.groups;
  }

  async fetchMitarbeiterData () {  
    await this.coworkerModule.fetchData();    
    this.mitarbeiter = this.coworkerModule.coworkers;
  }

  async fetchAufgabenData () {  
    await this.taskModule.fetchData();    
    this.aufgaben = this.taskModule.tasks;
  }
}
