import Vue from 'vue'
import Vuex from 'vuex'
import Projekte from './projekte'
import Fachgruppen from './fachgruppen'
import Mitarbeiter from './mitarbeiter'
import Freitag from './freiTag'
import Termin from './termin'
import Aufgaben from './aufgaben'

Vue.use(Vuex)

class state {
  // Basispfad zur API
  //host = 'http://localhost';
  host = 'app'
  errormessage = 'fehler';
  infomessage = 'info';
  successmessage = 'success'
}

export default new Vuex.Store({
  modules: {
    Projekte,
    Fachgruppen,
    Mitarbeiter,
    Freitag,
    Termin,
    Aufgaben
  },
  state: new state(),
  mutations: {
  },
  actions: {
  }  
})
