import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Teammitarbeiter, IF_teammitarbeiter } from '@/models'
import axios from 'axios'

@Module({
  name: 'Mitarbeiter',
  stateFactory: true,
  namespaced: true
})
export default class Mitarbeiter extends VuexModule {
  coworkers: Array<Teammitarbeiter> = []

  @Mutation
  POST_axios (pl: {path: string, obj: IF_teammitarbeiter}) {    
    axios.post(
      pl.path,
      pl.obj, 
      { headers: {'Content-type': 'application/x-www-form-urlencoded'} }
    )
    .then(response => {
      console.log(response.data.message)
    })
    .catch( function (error) {
      console.error(error)
    });
  }

  @Mutation
  SET_coworkers(Teammitarbeiter: Array<Teammitarbeiter>) {
    this.coworkers = Teammitarbeiter;
  }

  get getWorkerOptions () {    
    return this.coworkers.map((val,i) => ({value: i, text: val.name}))
  }

  @Action
  async fetchData () {
    await axios.get(this.context.rootState.host + '/coworkers/get.php')
    .then(response => {
      if(response.data.records.length > 0)
        this.SET_coworkers(response.data.records);
      else
        console.log('fetch:' + response.data.message);
    })
    .catch( (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 404)
          this.context.rootState.infomessage = error.response.data.message
        console.error(error.response.data.message)
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(error.request);
        this.context.rootState.errormessage = 'Verbindungsfehler zur API!'
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', error.message);
        this.context.rootState.errormessage = 'Keine Verbindung zur Datenbank!'
      }
    });
  }

  //{name: string, short: string, email: string, phone: number}
  @Action
  addMitarbeiter (m : Teammitarbeiter) {
    this.POST_axios({path: this.context.rootState.host + '/coworkers/add.php', obj: m });
  }

  @Action
  delMitarbeiter (ID: number) {
    this.POST_axios({path: this.context.rootState.host + '/coworkers/del.php', obj: { ID } });
  }

  @Action
  changeMitarbeiter (m: Teammitarbeiter) {
    this.POST_axios({path: this.context.rootState.host + '/coworkers/change.php', obj: m });
  }
}