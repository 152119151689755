




















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class CalEdit extends Vue {
  @Prop() private value!: Date;
  private tempdate = this.value;
  private toggle = true;

  get dateElem():Date { 
    return new Date(this.tempdate);
  }

  set dateElem(newVal: Date) {
    this.tempdate = new Date(newVal);
    this.$emit('input', this.tempdate);
  }

  //.toLocaleDateString(undefined, { weekday: "short", year: "numeric", month: "2-digit", day: "2-digit"})
}
