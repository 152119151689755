import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Fachgruppe } from '@/models'
import axios from 'axios'

@Module({
  name: 'Fachgruppen',
  stateFactory: true,
  namespaced: true
})
export default class Fachgruppen extends VuexModule {
  groups: Array<Fachgruppe> = []

  @Mutation
  POST_axios (pl: {path: string, obj: {ID?: number ; name?: string}}) {    
    axios.post(
      pl.path,
      pl.obj, 
      { headers: {'Content-type': 'application/x-www-form-urlencoded'} }
    )
    .then(response => {
      console.log(response.data.message)
    })
    .catch( function (error) {
      console.error(error)
    });
  }

  @Mutation
  SET_groups(Fachgruppen: Array<Fachgruppe>) {
    this.groups = Fachgruppen;
  }

  get getGroupsOptions () {    
    return this.groups.map((val,i) => ({value: i, text: val.name}))
  }

  @Action
  async fetchData () {
    const response = await axios.get(this.context.rootState.host + '/group/get.php');
    if(response.data.records.length > 0)
      this.SET_groups(response.data.records);
    else
      console.log(response.data.message)
  }

  @Action
  addFachgruppe (fachgruppenname: string) {
    this.POST_axios({path: this.context.rootState.host + '/group/add.php', obj: { name: fachgruppenname } });
  }

  @Action
  delFachgruppe (ID: number) {
    this.POST_axios({path: this.context.rootState.host + '/group/del.php', obj: { ID } });
  }

  @Action
  changeFachgruppe (p: {ID: number; name: string}) {
    this.POST_axios({path: this.context.rootState.host + '/group/change.php', obj: p });
  }
}
