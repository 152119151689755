















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class SelectEdit extends Vue{
  @Prop() private value!: string;
  @Prop() private options!: {value: number ; text: string}[];
  private tempElem = this.value;
  private isEditing = false;

  public edit() {        
    this.isEditing = true;
    const select = this.$el.getElementsByTagName('select')[0];
    select.addEventListener("blur", this.editEnd);    
    select.style.display = '';
    select.focus();
  }

  public editEnd() {
    this.isEditing = false;
    if(this.value !== this.tempElem)
      this.$emit('input', this.tempElem);
  }
}
